<template>
  <div class="w-full flex flex-col">
    <loader :loading="loading" :backdrop="true"/>
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-2 text-2xl mb-2">Dashboard</h3>
    <div class="w-full bg-white my-4">
      <select-input
        class="w-full"
        idInput="filterByInput"
        idFormGroup="filterBy-group"
        v-model="filterBySelect"
        :options="filterListOptions"
      />
    </div>
    <section v-if="menuOptons.length" class="w-full flex grid grid-cols-5">
      <div v-for="(menu, i) in menuOptons"
      v-bind:key="i"
      class="flex h-16 mx-1 pb-2 bg-white rounded-lg overflow-hidden">
        <img
          class="object-cover h-16 w-16"
          :src="menu.img"
          :alt="menu.title"
          />

        <div class="relative">
          <h3 class="text-base md:text-md font-small text-gray-800 mx-2 mt-2">
            {{menu.title}}
          </h3>

          <p class="mt-2 text-base md:text-ls text-gray-600 mx-2">
            {{menu.info}}
          </p>
        </div>
      </div>
    </section>
    <div class="bg-white mt-4 rounded-lg">
      <h4 class="mx-2 pt-2">Historial de Afiliación</h4>

      <div class="w-full my-4">
        <area-chart
        class="w-full my-4"
        :dataChart="isRegister ? chartData : chartDataPackager"/>
      </div>
      <!-- <div v-if="!isRegister" class="w-full my-4">
        <area-chart
        class="w-full my-4"
        :dataChart="chartDataPackager"/>
      </div> -->
    </div>
    <dashboard-table class="mt-4 rounded-lg"
    v-if="filterBySelect === filterListOptions[0].value"/>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { mapGetters } from 'vuex';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import AreaChart from '@/components/AreaChart.vue';
import SelectInput from '@/components/SelectInput.vue';
import DashboardTable from '../components/DashboardTable.vue';
import Loader from '@/components/Loader.vue';
import request from '@/api/request-services';

export default {
  name: 'Home',
  components: {
    BreadCrumbs,
    AreaChart,
    SelectInput,
    DashboardTable,
    Loader,
  },
  data() {
    return {
      loading: false,
      routes: [],
      isRegister: true,
      chartData: [100, 75, 82, 91, 71, 71, 0, 35, 32, 20, 0, 50],
      chartDataPackager: [75, 82, 91, 71, 100, 20, 55, 0, 40, 11, 30, 50],
      menuOptons: [],
      filterBySelect: 'registros',
      filterListOptions: [
        { text: 'Registros', value: 'registros' },
        { text: 'Paquetes', value: 'paquetes' },
      ],
    };
  },
  watch: {
    filterBySelect() {
      this.isRegister = this.filterBySelect === 'registros';
    },
  },
  computed: {
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
    async loadCardsinfo() {
      this.loading = true;
      const response = await request.requestGET('dashboard', this.getToken);

      if (response.status === 200) {
        const { data: { users } } = response;
        this.menuOptons = [
          {
            title: 'Total Usuarios',
            key: 'total_users',
            info: users.total_users,
            img: require('@/assets/img/total.svg'),
          },
          {
            title: 'Usuarios Activos',
            key: 'active_users',
            info: users.active_users,
            img: require('@/assets/img/active.svg'),
          },
          {
            title: 'Usuarios Inactivos',
            key: 'inactive_users',
            info: users.inactive_users,
            img: require('@/assets/img/inactive.svg'),
          },
          {
            title: 'Usuarios Pendientes',
            key: 'pending_users',
            info: users.pending_users,
            img: require('@/assets/img/pending.svg'),
          },
          {
            title: 'Usuarios Del mes',
            key: 'monthly_users',
            info: users.monthly_users,
            img: require('@/assets/img/monthly.svg'),
          },
        ];
        this.loading = false;
      } else this.loading = false;
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
    this.loadCardsinfo();
  },
};
</script>

<style scoped>
h4 {
    font-size: 1.5rem !important;
}
</style>
