<script>
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    dataChart: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      gradient: null,
    };
  },
  watch: {
    dataChart() {
      this.renderChart(
        {
          labels: [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Setiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ],
          datasets: [
            {
              label: 'Afiliación',
              borderColor: '#329BB8',
              pointBackgroundColor: 'white',
              pointBorderColor: 'white',
              borderWidth: 1,
              backgroundColor: this.gradient,
              data: this.$props.dataChart,
            },
          ],
        },
        { responsive: true, maintainAspectRatio: false },
      );
    },
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, 'rgba(8, 87, 163, 0.9)');
    this.gradient.addColorStop(0.5, 'rgba(8, 87, 163, 0.25)');
    this.gradient.addColorStop(1, 'rgba(8, 87, 163, 0)');

    this.renderChart(
      {
        labels: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Setiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        datasets: [
          {
            label: 'Afiliación',
            borderColor: '#329BB8',
            pointBackgroundColor: 'white',
            pointBorderColor: 'white',
            borderWidth: 1,
            backgroundColor: this.gradient,
            data: this.$props.dataChart,
          },
        ],
      },
      { responsive: true, maintainAspectRatio: false },
    );
  },
};
</script>
