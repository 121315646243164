<template>
  <div class="w-full bg-white p-2 overflow-auto">
    <div class="w-full flex justify-between mb-4">
        <label class="self-center my-2">Últimos 30 dias</label>
      </div>
    <b-table
      class="my-2 text-sm text-center"
      id="dashboardTable"
      striped
      hover
      :fields="fields"
      :items="items"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(id)="data">
        {{ data.value }}
      </template>
      <template #cell(index)="data">
        GV-{{ data.item.id }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(user_information)="data">
        <b class="text-information cursor-pointer">
          <router-link
            class="no-underline"
            :to="{ name: 'Información Usuario', params: { id: data.item.id } }">
            {{data.item.user_information.first_name != null ?
            data.item.user_information.first_name
            : ''}}
            {{data.item.user_information.last_name != null ?
            data.item.user_information.last_name
            : ''}}
          </router-link>
        </b>
      </template>

      <!-- A virtual composite column -->
      <template #cell(email)="data">
        <b>{{data.value}}</b>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template id="dashboardIcon" #cell(open)="data">
        <button
          @click="() => $router.push({
            name: 'Información Usuario',
            params: { id: data.item.id }
          })">
          <icon class="text-center" name="ChevronRightDoubleIcon" />
        </button>
      </template>
    </b-table>
    <b-pagination
      class="mt-4"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/Icon.vue';
import request from '@/api/request-services';

export default {
  name: 'DashboardTable',
  components: { Icon },
  props: {},
  computed: {
    rows() {
      return this.items.length;
    },
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    handleCreateAdmin() {
      const role = 5;
      this.$router.push({ name: 'Crear Usuario', params: { role } });
    },
    async handleData() {
      const response = await request.requestGET('dashboard', this.getToken);
      if (response.status === 200) {
        this.items = response.data.last_30_registers;
      }
    },
  },
  mounted() {
    this.handleData();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      items: [],
      fields: [
        { key: 'id', label: '#' },
        { key: 'index', label: 'Codigo' },
        { key: 'user_information', label: 'Nombre' },
        { key: 'email', label: 'Correo Eléctronico' },
        { key: 'open', label: 'Abrir' },
      ],
    };
  },
};
</script>

<style scoped>
  .text-information {
    color: var(--primary-blue) !important;
  }
</style>
